import React, { useState } from 'react';
import SnakeHighscore from './SnakeHighscore';
import SnakeGame from './SnakeGame';

const SnakeMenu = (props) => {
    let username = props.username;
    let token = props.token;
    const [gameMode, setGameMode] = useState(false);

    const startGameMode = () => {
        setGameMode(true);
    }

    const stopGameMode = () => {
        setGameMode(false);
    }

    return (
        <>
            {
            gameMode ? 
            <SnakeGame token={token} username={username} setGameMode={stopGameMode} /> 
            : <SnakeHighscore token={token} username={username} setGameMode={startGameMode} />
            }
        </>
    );

}

export default SnakeMenu;