import React, { useEffect } from 'react';
import ParticleBackground from './ParticleBackground';
import '../Styles/Dashboard.css';

const ComingSoon = () => {
    useEffect(() => {
        document.title = "Coming Soon . . ."
     }, []);
    return (
        <>
            <ParticleBackground />
            <div className="center-screen">
            <div className="dashboard-container">
                <h1 className="dashboard-title">Coming Soon</h1>
                <h3>1. Minesweeper</h3>
                <h3>2. Tic Tac Toe with multiplayer</h3>
            </div>
            </div>
        </>
    );
}

export default ComingSoon;