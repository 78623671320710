import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import "../Styles/Navbar.css"
const AppNav = () => {
    return (
        <Navbar class=".navbar"  data-bs-theme="dark" expand="lg" style={{ width: '100%', marginLeft: '0', marginRight: '0', backgroundColor: "#5589aa", opacity: 0.7}}>
   
          
            <div style={{ width: '1%' }}></div>
          <Navbar.Brand href="/">didianq.com</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className=".navlink" href="/">Dashboard</Nav.Link>
              <Nav.Link href="/gallery">Photo Gallery</Nav.Link>
                <Nav.Link href="/snake">Snake Game</Nav.Link>
                <Nav.Link href="/comingsoon">Coming Soon</Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link className=".navlink" href="/changepassword">Change Password</Nav.Link>
            <div style={{ width: '1%' }}></div>
          </Navbar.Collapse>
        </Navbar>
      );
    };
export default AppNav;