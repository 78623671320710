export const setToken = (token) => {
    localStorage.setItem('token', JSON.stringify(token));
}

export const setUsername = (username) => {
    localStorage.setItem('username', JSON.stringify(username));
}

export const setSession = (username, token) => {
    setToken(token);
    setUsername(username);
}

export const clearSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
}

export const getToken = () => {
    return JSON.parse(localStorage.getItem('token'));
}

export const getUsername = () => {
    if (localStorage.getItem("username") !== null) {
        return JSON.parse(localStorage.getItem("username"));
    } return null;
}

export const isAuthed = () => {
    if (getToken() !== null) {
        return true;
    }
    return false;
}

export const refreshPage = () => window.location.reload();