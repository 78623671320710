import Swal from 'sweetalert2';

export const alert_success = (title, text) => {
    Swal.fire({
        icon: 'success',
        title: title,
        text: text
    });
}

export const alert_success_callback = (title, text, callback) => {
    Swal.fire({
        icon: 'success',
        title: title,
        text: text
    }).then(callback);
}

export const alert_error = (title, text) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: text
    });
}

export const alert_error_callback = (title, text, callback) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: text
    }).then(callback);
}