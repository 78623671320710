import React, { useState, useEffect } from 'react';
import { snake_highScores, snake_getUserHighScores } from '../../../Api/Api';
import Table from 'react-bootstrap/Table';
import '../../../Styles/SnakeHighscore.css'; // Import the CSS file for styles

const SnakeHighscore = (props) => {
    const [highScores, setHighScores] = useState([]);
    const [userHighScores, setUserHighScores] = useState([]);
    const token = props.token;
    const username = props.username;

    useEffect(() => {
        document.title = "Snake Leaderboards"
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            snake_highScores(token).then(res => {
                if (res.length > 0) {
                    setHighScores(res.splice(0, 5));
                }
            });
            snake_getUserHighScores(token).then(res => {
                if (res.length > 0) {
                    setUserHighScores(res);
                }
            });
        }
        fetchData();
    },[]);

    return (
        <div className="leaderboard-container">
            <h2 className="leaderboard-title">Leaderboard</h2>
            <button className="start-game-button" onClick={() => props.setGameMode(true)}>Start Game</button>
            <div className="leaderboard-section">
                <div className="all-users">
                    <h3>All Users</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {highScores.map((score, index) => (
                                <tr key={index}>
                                    <td>{score.username}</td>
                                    <td>{score.score}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="personal-bests">
                    <h3>Your Best Times</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userHighScores.map((score, index) => (
                                <tr key={index}>
                                    <td>{score.score}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <p>Your Username: {username}</p>
                </div>
            </div>
        </div>
    );
};

export default SnakeHighscore;
