//Just a Credit page to Nicholas Morvan and Zackary Morvan

import React, {useEffect} from 'react';
import { image_route } from '../Api/Api';
import ParticleBackground from './ParticleBackground';
import '../Styles/EasterEgg.css';

const EasterEgg = () => {
    useEffect(() => {
        document.title = "Easter EGGGGG"
     }, []);
    let easter_egg_img = image_route+"EASTER_EGG.jpg";
    return (
        <>
            <ParticleBackground />
            <div className="center-screen">
                <div className="easteregg-container">
                    <h1 className="easteregg-title">Easter Egg</h1>
                    
                    <img className="easteregg-photo" src={easter_egg_img} alt="Easter Egg"/>
                    <h3>Created by yours truly</h3>
                    <h4>Zackary Morvan</h4>
                    <h4>Nicholas Morvan</h4>
                    <h5>Ont t'aime plus!</h5>
                </div>
            </div>
        </>
    );
}

export default EasterEgg;