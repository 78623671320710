
import { image_list, image_route } from "../Api/Api";
import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../Styles/PictureGallery.css';
import ParticleBackground from "./ParticleBackground";
import { Container } from "react-bootstrap";
import AppNav from "./Navbar";

const FetchImages = async (token) => {
    let imageList = [];
    await image_list(token).then(res => {
        if (res != null) {
            res.forEach(img => {
                imageList.push({
                    original: image_route+img,
                    thumbnail: image_route+img
                });
            });
        }
    });
    return imageList;
}

const PictureGallery = (props) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        document.title = "Photo Gallery"
     }, []);
    useEffect(() => {
        async function fetchImages() {
            await FetchImages(props.token).then(res => {
                setImages(res);
            });
        }
        fetchImages();
    }, []);

    return (
        <div>
        <ParticleBackground />
        <div className="center-screen">
        <Container className="gallery-container">
            <div className="gallery-border">
            <h1 className="gallery-title">Photo Gallery</h1>
            <Gallery items={images} />
            </div>
        </Container>
        </div>
        </div>
    );
}

export default PictureGallery;