import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getToken, getUsername } from "./Api/BrowserManager";
import LoginPage from "./Components/LoginPage";
import PictureGallery from "./Components/PictureGallery";
import Dashboard from "./Components/Dashboard";
import Snake from "./Components/Games/Snake/SnakeMenu";
import ComingSoon from "./Components/ComingSoon";
import EasterEgg from "./Components/EasterEgg";
import ChangePassword from "./Components/ChangePassword";

const Router = (props) => {
    let authed = props.authed;

    console.log(authed);
    if (authed == true) {
        let token = getToken();
        let username = getUsername();
        return (
                <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Dashboard token={token} username={username} />} />
                        <Route path="/gallery" element={<PictureGallery token={token} username={username} />} />
                        <Route path="/snake" element={<Snake token={token} username={username} />} />
                        <Route path="/comingsoon" element={<ComingSoon/>} />
                        <Route path="/easteregg" element={<EasterEgg/>} />
                        <Route path="/changepassword" element={<ChangePassword token={token} username={username} />} />
                    </Routes>
                </BrowserRouter>
                </>
        );
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;