import axios from 'axios';
import { setSession, setUsername, clearSession, setToken } from './BrowserManager';

export const base_route = "https://didiandq.com/";
export const api_route = base_route + "api/v1/";
export const image_route = base_route + "image/";

//Function allows users to login
export const login = async (username, password) => {
    let token = null;
    let data = {"username": username, "password": password};
    //Uses the axios library to make a post request to the login endpoint
    //If the request is successful, the token is returned
    //If it fails then null is returned
    await axios.post(api_route+"login", data).then(res => {
        console.log(res)
        if (res.status == 200) {
            token = res.data.token;
        }
    }).catch(err => {

    });
    return token;
}

export const change_password = async (token, old_password, new_password) => {
    let success = false;
    let data = {"old_password": old_password, "new_password": new_password};
    await axios.post(api_route+"change_password", data, {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(res => {
        console.log(res)
        if (res.status == 200) {
            success = true;
        }
    }).catch(err => {
        success = false;
    });
    return success;
}

//Function allows users to validate their current Bearer token
export const validate = async (token) => {
    let valid = false;
    //Post to the validate route with the token in the header
    //If it succeeds set the new token and return true
    //If it fails return false and clear the current session
    await axios.get(api_route+"validate", {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(res => {
        if (res.status == 200) {
            setToken(res.data.token);
            setUsername(res.data.username);
            valid = true;
        } else {
            clearSession();
            valid = false;
        }
    }).catch(err => {
        clearSession();
        valid = false;
    });
    return valid;
}

//Function allows users to fetch all available images on the server
export const image_list = async (token) => {
    let images = [];
    await axios.get(api_route+"images/list", {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(res => {
        if (res.status == 200) {
            images = res.data.images;
        }
    }).catch(err => {
        images = [];
    });
    return images;
}

//Function allows users to fetch the top 10 high scores for snake
export const snake_highScores = async (token) => {
    let scores = [];
    await axios.get(api_route+"snake/highScores", {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(res => {
        if (res.status == 200) {
            scores = res.data.scores;
        }
    }).catch(err => {
        scores = [];
    });
    return scores;
}

//Function allows users to fetch their own top 5 high scores for snake
export const snake_getUserHighScores = async (token) => {
    let scores = [];
    await axios.get(api_route+"snake/getUserHighScores", {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(res => {
        if (res.status == 200) {
            scores = res.data.scores;
        }
    }).catch(err => {
        scores = [];
    });
    return scores;
}

//Function allows users to post their high score for snake
export const snake_postScore = async (token, score) => {
    let newHighScore = false;
    let data = {"score": score};
    await axios.post(api_route+"snake/postScore", data, {
        headers: {"Authorization": `Bearer ${token}`}
    }).then(res => {
        if (res.status == 200) {
            newHighScore = res.data.new_highscore;
        }
    }).catch(err => {
        newHighScore = false;
    });
    return newHighScore;
}
