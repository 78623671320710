// Snake.js
import {Container} from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import "../../../Styles/Snake.css"; // Import the CSS file
import { snake_highScores, snake_getUserHighScores, snake_postScore } from '../../../Api/Api';
const ROWS = 25;
const COLS = 25;
const INITIAL_SNAKE = [{ row: 12, col: 12 }];
const INITIAL_DIRECTION = 'RIGHT';

const generateFood = () => {
  return {
    row: Math.floor(Math.random() * ROWS),
    col: Math.floor(Math.random() * COLS),
  };
};

const Snake = (props) => {

  useEffect(() => {
    document.title = "Snake Game"
  }, []);

  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [food, setFood] = useState(generateFood());
  const [gameOver, setGameOver] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [score, setScore] = useState(0);


  let token = props.token;
  let username = props.username;
  let setGameMode = props.setGameMode;

  const checkCollision = (snake) => {
    const head = snake[0];
    return (
      snake.slice(1).some((segment) => segment.row === head.row && segment.col === head.col) ||
      head.row < 0 ||
      head.row >= ROWS ||
      head.col < 0 ||
      head.col >= COLS
    );
  };

  const resetGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    setFood(generateFood());
    setGameOver(false);
    setScore(0);
  };



  useEffect(() => {
    if (!gameOver && !isPause) {
      // Snake Movement Logic
const moveSnake = () => {
    const newSnake = [...snake];
    const head = { ...newSnake[0] };
  
    switch (direction) {
      case 'UP':
        head.row = (head.row - 1 + ROWS) % ROWS;
        break;
      case 'DOWN':
        head.row = (head.row + 1) % ROWS;
        break;
      case 'LEFT':
        head.col = (head.col - 1 + COLS) % COLS;
        break;
      case 'RIGHT':
        head.col = (head.col + 1) % COLS;
        break;
      default:
        break;
    }
  
    newSnake.unshift(head);
  
    if (head.row === food.row && head.col === food.col) {
      setFood(generateFood());
      setScore(score + 10);
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);

    if (checkCollision(newSnake)) {
      snake_postScore(token, score);
      setGameOver(true);
    }
  };
  
      const gameInterval = setInterval(moveSnake, 120);
  
      return () => {
        clearInterval(gameInterval);
      };
    }
  }, [snake, direction, food, gameOver, isPause, score]);
  
  useEffect(() => {
  const handleKeyPress = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        if (direction !== 'RIGHT' || snake.length === 1 || direction == 'LEFT') // Allows turning if the snake is of length 1
          setDirection('LEFT');
        break;
      case 'ArrowDown':
        if (direction !== 'LEFT' || snake.length === 1 || direction == 'RIGHT')
          setDirection('RIGHT');
        break;
      case 'ArrowLeft':
        if (direction !== 'DOWN' || snake.length === 1 || direction == 'UP')
          setDirection('UP');
        break;
      case 'ArrowRight':
        if (direction !== 'UP' || snake.length === 1 || direction == 'DOWN')
          setDirection('DOWN');
        break;
      default:
        break;
    }
  };

  document.addEventListener('keydown', handleKeyPress);
  
  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('keydown', handleKeyPress);
  };
}, [direction, snake]);
return (
  <Container className="snake-container">
  <div className="SnakeGame">
    <h1>React Snake Game</h1>

    <div className="game-board">
{/* Rendering game grid */}
{Array.from({ length: ROWS }).map((_, rowIndex) => (
  <div key={rowIndex} className="row">
    {Array.from({ length: COLS }).map((_, colIndex) => {
      const isSnakeSegment = snake.some(
        (segment) => segment.row === rowIndex && segment.col === colIndex
      );

      const isFood = food.row === rowIndex && food.col === colIndex;

      return (
        <div
          key={colIndex}
          className={`cell ${isSnakeSegment ? 'snake' : ''} ${isFood ? 'food' : ''}`}
        ></div>
      );
    })}
  </div>
))}
</div>
    {/* Display game over dialog if game is over */}
    {gameOver && (
      <div className='dialog'>
        <div className='reset'>
          <p>Game Over! <b>Your Score is: {score}</b></p>
          <button onClick={resetGame}>Restart</button>
        </div>
      </div>
    )}

    {/* Pause button */}
    <div className='control'>
      <button onClick={() => { setIsPause(!isPause) }}> {isPause ? 'Resume' : 'Pause'}</button>
      {/* button to view leaderboards */}
      <button onClick={() => setGameMode(true)}>Leaderboards</button>
    </div>

    {/* Score display */}
    <p> Score : 🚀 {score}</p>

    {/* Arrow buttons */}
    <div className='button'>
      <button className="up" onClick={() => setDirection('LEFT')} >↑</button><br />
      <button className="left" onClick={() => setDirection('UP')} >←</button>
      <button className="right" onClick={() => setDirection('DOWN')}>→</button><br />
      <button className="down" onClick={() => setDirection('RIGHT')}>↓</button>
    </div>
  </div>
  </Container>
);
};

export default Snake