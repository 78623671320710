import React, { useEffect, useState } from 'react';
import { change_password } from "../Api/Api";
import { clearSession, refreshPage } from '../Api/BrowserManager';
import { useNavigate } from 'react-router-dom';
import { alert_success_callback, alert_error } from "../Api/Alerts";
import { Container, Form, Button } from 'react-bootstrap';
import ParticleBackground from './ParticleBackground';
import '../Styles/Global.css';

const ChangePassword = (props) => {
    useEffect(() => {
        document.title = "Change Password"
     }, []);
    const navigate = useNavigate();
    let token = props.token;
    let username = props.username;

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    
    const alertCallback = () => {
        clearSession();
        navigate("/");
        refreshPage();
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (newPassword != confirm) {
            alert_error("Error", "Passwords do not match!");
            return;
        }
        await change_password(token, oldPassword, newPassword).then(res => {
            if (res == true) {
                alert_success_callback("Success", "Password changed successfully!", alertCallback);
            } else {
                alert_error("Error", "Invalid password!");
            }
        });
    }

    return (
        <>
        <ParticleBackground />
        <div className="center-screen">
        <Container className="custom-container">
            <div className="login-container">
            <Form onSubmit={handlePasswordChange}>
                <Form.Group controlId="formOldPassword">
                <Form.Label>Old Password:</Form.Label>
                <Form.Control
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter your old password"
                />
                </Form.Group>
                <Form.Group controlId="formNewPassword">
                <Form.Label>New Password:</Form.Label>
                <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password"
                />
                </Form.Group>
                <Form.Group controlId="formConfirm">
                <Form.Label>Confirm Password:</Form.Label>
                <Form.Control
                    type="password"
                    value={confirm}
                    onChange={(e) => setConfirm(e.target.value)}
                    placeholder="Confirm your new password"
                />
                </Form.Group>
                <Button variant="primary" type="submit">
                Submit
                </Button>
            </Form>
            </div>
        </Container>
        </div>
        </>
    );


}

export default ChangePassword;