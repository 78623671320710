import React, { useEffect } from 'react';
import ParticleBackground from './ParticleBackground';
import '../Styles/Dashboard.css';
import { useNavigate, NavLink, Link } from 'react-router-dom';

const Dashboard = () => {
  useEffect(() => {
    document.title = "Dashboard"
 }, []);
    return (
      <>
        <ParticleBackground />
        <div className="center-screen">
          <div className="dashboard-container">
            <h1 className="dashboard-title">Welcome to the Dashboard</h1>
            <div className="dashboard-links">
              <Link to="/gallery" className="dashboard-link">
                View Gallery
              </Link>
              <Link to="/snake" className="dashboard-link">
                Play Snake
              </Link>
              {/* Add more links for additional routes as needed */}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Dashboard;