import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { isAuthed, getToken, getUsername, refreshPage, clearSession } from './Api/BrowserManager';
import { validate } from './Api/Api';
import { alert_error_callback } from './Api/Alerts';
import Router from './Router';
import AppNav from './Components/Navbar';
function App() {
  let authed = isAuthed();
  let token = getToken();
  let history = createBrowserHistory();

  useEffect(() => {
      async function validate_token() {
        await validate(token).then(res => {
          if (res == false) {
            authed = false;
            clearSession();
            history.push("/")
            console.log("Session expired, please log in again!");
            alert_error_callback("Error", "Your session has expired, please log in again!", refreshPage);
          }
        });
      }
      if (token != null) {
        validate_token();
      }
  }, []);
  if (authed) {
    return (
      <div className="App">
        <AppNav />
        <Router authed={true} />
      </div>
    )
  }
  return (
    <div className="App">
      <Router authed={false} />
    </div>
  );

}

export default App;
