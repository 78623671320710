import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { login } from "../Api/Api";
import { refreshPage, setSession } from "../Api/BrowserManager";
import { alert_success, alert_error } from "../Api/Alerts";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/LoginPage.css';
import '../Styles/Global.css';
import { Container, Form, Button } from 'react-bootstrap';
import Christmas from '../svg/christmas.svg';
import ParticleBackground from './ParticleBackground';

const LoginPage = (props) => {
  useEffect(() => {
    document.title = "Login Page"
 }, []);
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();
        await login(username, password).then(token => {
            if (token != null) {
                setSession(username, token)
                navigate("/");
                refreshPage();
                alert_success("Welcome back!", "You have successfully logged in!");
            } else {
                alert_error("Error", "Invalid username or password!");
            }
        });

    }



    return (
        <>
      <ParticleBackground />
      <div className="center-screen">
        <Container className="custom-container">
          <div className="login-container">
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formUsername">
                <Form.Label>Username:</Form.Label>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </>
  
    );
}

export default LoginPage;